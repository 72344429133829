
:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(162, 61%, 89%);
  --clr-primary-2: hsl(162, 60%, 78%);
  --clr-primary-3: hsl(162, 61%, 67%);
  --clr-primary-4: hsl(162, 61%, 57%);
  /* primary/main color */
  --clr-primary-5: hsl(162, 73%, 46%);
  /* lighter shades of primary color */
  --clr-primary-6: #1aa179;
  --clr-primary-7: #13795b;
  --clr-primary-8: #0d503c;
  --clr-primary-9: #06281e;
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(212, 33%, 89%);
  --clr-grey-2: hsl(210, 31%, 80%);
  --clr-grey-3: hsl(211, 27%, 70%);
  --clr-grey-4: hsl(209, 23%, 60%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 28%, 39%);
  --clr-grey-7: hsl(209, 34%, 30%);
  --clr-grey-8: hsl(211, 39%, 23%);
  --clr-grey-9: hsl(209, 61%, 16%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 9px 45px rgb(65, 61, 68);
  --max-width: 1170px;
  --fixed-width: 450px;
  --clr-pink: #ba8af2;
}
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
  
}

@media screen and (min-width: 992px) {
  .section {
    width: 65vw;
  }
}

main {
  min-height:90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mycontainer {
  width:20vw;
  height:18vw;
  margin: 5rem 0;
  padding-bottom: 100px;
  max-width: var(--fixed-width);
  background: var(--clr-white);
  border-radius:6px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: var(--dark-shadow);
  position: absolute;
  right: 0;
  top: 0;
}
.mycontainer h3 {
  font-weight: normal;
  text-transform: none;
  margin-bottom: 2rem;
}

.person img {
  width:50px;
  height:50px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: var(--light-shadow);

}
.person h4 {
  margin-bottom: 0.35rem;
}
.person p {
  margin-bottom: 0;
}

/* src/PersonCard.css */
.person {
    display: flex;
    align-items: center;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 4px;
   
  }
  
  .person img {
    width:30px;
    height:30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 12px;
  }
  
  
.mycontainer button {
  color: var(--clr-white);
  width: 70%;
  border-color: transparent;
  background:  var(--clr-grey-8);
  margin: 2rem auto 0 auto;
  font-size: 1.2rem;
  padding: 0.1rem 0;
  border-radius: var(--radius);
  outline: 1px solid rgba(173, 49, 245, 0.8);
 
}
