.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.editable-field {
  border: none; /* Remove the border */
  border-bottom: 2px solid rgb(127, 127, 249); /* Add a bottom border to create the underline */
  display: inline-block;
  box-sizing: border-box;
  padding: 5px; /* Optional: Add padding for better appearance */
  cursor: line;
}

/* Optional: Add hover effect for better user experience */
.editable-field:hover {
  border-bottom: 2px solid red; /* Change the color on hover or customize as needed */
  cursor: text; /* Change the cursor to indicate text input */
}
.header-cell{
  text-align: center;
  width: 100%;
}
.d-profile{
  position : absolute;
  top : 5.6rem;
  z-Index : 999;
  background:#fff;
  padding:1rem;
  border-Radius:5px;
  box-Shadow:0px 0px 5px #000;
  width:10rem;
}
.d-profile h5{
  margin: 5px;
}
.d-profile Button{
  margin: 5px;
}
.page-body {
  /* background-color: #c4dee3; */
  background-image: url('assets/background.jpg');
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  height: 100vh;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding: 20px;  */
}

.navbar-custom {
  /* background-color: #9fb9f0;
  opacity: 0.5; */
}
.login-container {
  background: hsla(0, 0%, 100%, 0.8);
  backdrop-filter: blur(30px);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100vh;
  max-width: 100%;
  margin-left: 26%;
  margin-top: 10%;
}

.reset-password {
  color: blue; /* Change text color to blue */
  text-decoration: underline; /* Add underline */
  cursor: pointer; /* Change cursor to pointer on hover */
  /* Add any additional styles you want */
}

/* Loader styles */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.circular-loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #22a6b3;
  animation: spin 1s ease infinite;
}

/* Media query for smaller screens */
@media screen and (max-width: 1200px) {
  .login-container {
    width: 80vw; /* Adjust width for larger screens */
  }
}

@media screen and (max-width: 768px) {
  .login-container {
    width: 90vw; /* Adjust width for smaller screens */
  }
}

@media screen and (max-width: 576px) {
  .login-container {
    width: 100%; /* Adjust width for mobile devices */
    padding: 10px; /* Reduce padding for smaller screens */
  }
}

.dashboard-container {
  display: flex;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
