h1 {
    color:green;
   }
.badge {
    position: relative;
    top: -18px;
    left: -25px;
    border: 1px solid black;
    border-radius: 50%;
   }
button {
    margin:5px;
    border-radius: 10%;
   }
/* 
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {

  } */
  .carousel-control-next-icon{
    margin-left: 8px;
  }
  .carousel-control-prev-icon{
    margin-right: 10px;
  }
  .save_button {
  
    max-width: 200px;
    height: 40px;
    background-color: rgb(158, 216, 250);
    color: aliceblue;
}

.employee-info {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.employee-info__name,
.employee-info__id {
  margin: 0;
}
