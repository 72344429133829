/* ClickJack.module.css */
.warningContainer {
    padding: 40px;
    background-color: #f2f2f2; /* Soft gray background for a less alarming alert */
    border: 1px solid #ccc; /* Subtle border */
    color: #db1212; /* Dark gray for text, less harsh than black */
    margin: 50px auto; /* Centering the container */
    width: 80%; /* Appropriate width */
    max-width: 600px; /* Maximum width */
    border-radius: 10px; /* Rounded corners for a smoother look */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Soft shadow for depth */
    text-align: center; /* Center text alignment */
  }
  
  .safeLink {
    display: inline-block;
    background-color: #4CAF50; /* Green button to suggest safety and go-ahead */
    color: white;
    padding: 12px 24px;
    margin-top: 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .safeLink:hover {
    background-color: #45a049; /* Darker shade on hover for interaction feedback */
  }
  .Message{
    color: #db1212;
  }
  